@import url("https://fonts.googleapis.com/css?family=Raleway:400,400i,700");
@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Purple+Purse&display=swap");

:root {
    --primary-red: #ED7208;
    --primary-red--dark-1: #b65604;
    --primary-green: #58b99d;
    --primary-dark-blue: #112E4A;
}

body {
    margin: 0;
    padding: 0;
    border: 0;
    font-weight: normal;
    vertical-align: baseline;
    text-decoration: none;
}

.nav__wrapper,.nav__wrapper div,.nav__wrapper span,.nav__wrapper applet,.nav__wrapper object,.nav__wrapper iframe,
.nav__wrapper h1,.nav__wrapper h2,.nav__wrapper h3,.nav__wrapper h4,.nav__wrapper h5,.nav__wrapper h6,.nav__wrapper p,.nav__wrapper blockquote,.nav__wrapper pre,
.nav__wrapper a,.nav__wrapper abbr,.nav__wrapper acronym,.nav__wrapper address,.nav__wrapper big,.nav__wrapper cite,.nav__wrapper code,
.nav__wrapper del,.nav__wrapper dfn,.nav__wrapper em,.nav__wrapper img,.nav__wrapper ins,.nav__wrapper kbd,.nav__wrapper q,.nav__wrapper s,.nav__wrapper samp,
.nav__wrapper small,.nav__wrapper strike,.nav__wrapper strong,.nav__wrapper sub,.nav__wrapper sup,.nav__wrapper tt,.nav__wrapper var,
.nav__wrapper b,.nav__wrapper u,.nav__wrapper i,.nav__wrapper center,
.nav__wrapper dl,.nav__wrapper dt,.nav__wrapper dd,.nav__wrapper ol,.nav__wrapper ul,.nav__wrapper li,
.nav__wrapper fieldset,.nav__wrapper form,.nav__wrapper label,.nav__wrapper legend,
.nav__wrapper table,.nav__wrapper caption,.nav__wrapper tbody,.nav__wrapper tfoot,.nav__wrapper thead,.nav__wrapper tr,.nav__wrapper th,.nav__wrapper td,
.nav__wrapper article,.nav__wrapper aside,.nav__wrapper canvas,.nav__wrapper details,.nav__wrapper embed,
.nav__wrapper figure,.nav__wrapper figcaption,.nav__wrapper footer,.nav__wrapper header,.nav__wrapper hgroup,
.nav__wrapper menu,.nav__wrapper nav,.nav__wrapper output,.nav__wrapper ruby,.nav__wrapper section,.nav__wrapper summary,
.nav__wrapper time,.nav__wrapper mark,.nav__wrapper audio,.nav__wrapper video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-weight: normal;
    vertical-align: baseline;
    text-decoration: none;
}
/* HTML5 display-role reset for older browsers */
.nav__wrapper article,.nav__wrapper aside,.nav__wrapper details,.nav__wrapper figcaption,.nav__wrapper figure,
.nav__wrapper footer,.nav__wrapper header,.nav__wrapper hgroup,.nav__wrapper menu,.nav__wrapper nav,.nav__wrapper section {
    display: block;
}
.nav__wrapper {
    line-height: 1;
}
.nav__wrapper ol,.nav__wrapper ul {
    list-style: none;
}
.nav__wrapper blockquote,.nav__wrapper q {
    quotes: none;
}
.nav__wrapper blockquote:before,.nav__wrapper blockquote:after,
.nav__wrapper q:before,.nav__wrapper q:after {
    content: '';
    content: none;
}
.nav__wrapper table {
    border-collapse: collapse;
    border-spacing: 0;
}
/******END RESET*********/
.destination-nav-menu div,.destination-nav-menu span,.destination-nav-menu applet,.destination-nav-menu object,.destination-nav-menu iframe,
.destination-nav-menu h1,.destination-nav-menu h2,.destination-nav-menu h3,.destination-nav-menu h4,.destination-nav-menu h5,.destination-nav-menu h6,.destination-nav-menu p,.destination-nav-menu blockquote,.destination-nav-menu pre,
.destination-nav-menu a,.destination-nav-menu abbr,.destination-nav-menu acronym,.destination-nav-menu address,.destination-nav-menu big,.destination-nav-menu cite,.destination-nav-menu code,
.destination-nav-menu del,.destination-nav-menu dfn,.destination-nav-menu em,.destination-nav-menu img,.destination-nav-menu ins,.destination-nav-menu kbd,.destination-nav-menu q,.destination-nav-menu s,.destination-nav-menu samp,
.destination-nav-menu small,.destination-nav-menu strike,.destination-nav-menu strong,.destination-nav-menu sub,.destination-nav-menu sup,.destination-nav-menu tt,.destination-nav-menu var,
.destination-nav-menu b,.destination-nav-menu u,.destination-nav-menu i,.destination-nav-menu center,
.destination-nav-menu dl,.destination-nav-menu dt,.destination-nav-menu dd,.destination-nav-menu ol,.destination-nav-menu ul,.destination-nav-menu li,
.destination-nav-menu fieldset,.destination-nav-menu form,.destination-nav-menu label,.destination-nav-menu legend,
.destination-nav-menu table,.destination-nav-menu caption,.destination-nav-menu tbody,.destination-nav-menu tfoot,.destination-nav-menu thead,.destination-nav-menu tr,.destination-nav-menu th,.destination-nav-menu td,
.destination-nav-menu article,.destination-nav-menu aside,.destination-nav-menu canvas,.destination-nav-menu details,.destination-nav-menu embed,
.destination-nav-menu figure,.destination-nav-menu figcaption,.destination-nav-menu footer,.destination-nav-menu header,.destination-nav-menu hgroup,
.destination-nav-menu menu,.destination-nav-menu nav,.destination-nav-menu output,.destination-nav-menu ruby,.destination-nav-menu section,.destination-nav-menu summary,
.destination-nav-menu time,.destination-nav-menu mark,.destination-nav-menu audio,.destination-nav-menu video {
   /* margin: 0;
    padding: 0; */
    border: 0;
    font-size: 100%;
    font: inherit;
    font-weight: normal;
    vertical-align: baseline;
    text-decoration: none;
}
/* HTML5 display-role reset for older browsers */
.destination-nav-menu article,.destination-nav-menu aside,.destination-nav-menu details,.destination-nav-menu figcaption,.destination-nav-menu figure,
.destination-nav-menu footer,.destination-nav-menu header,.destination-nav-menu hgroup,.destination-nav-menu menu,.destination-nav-menu nav,.destination-nav-menu section {
    display: block;
}
.destination-nav-menu {
    line-height: 1;
}
.destination-nav-menu ol,.destination-nav-menu ul {
    list-style: none;
}
.destination-nav-menu blockquote,.destination-nav-menu q {
    quotes: none;
}
.destination-nav-menu blockquote:before,.destination-nav-menu blockquote:after,
.destination-nav-menu q:before,.destination-nav-menu q:after {
    content: '';
    content: none;
}
.destination-nav-menu table {
    border-collapse: collapse;
    border-spacing: 0;
}
/*********END RESET 2*******/

.nav__wrapper {
    scroll-behavior: smooth;
}
.nav__wrapper {
    font-family: Raleway;
}
.nav__wrapper b {
    font-weight: bold;
}
.nav__wrapper h3 {
    font-size: 2rem;
}
.nav__wrapper h4 {
    font-size: 1.5rem;
}

.nav__wrapper .cta {
    padding: 0.875rem 1.5rem;
    border-radius: 3rem;
    background: var(--primary-red);
    color: white !important;
    border: none;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 300ms;
    font-size: 16px;
    display: block;
    width: fit-content;
    margin-left: 50px;
    margin-bottom: 0;
}
.nav__wrapper button {
    border: none;
    background-color: transparent;
    margin-bottom: 10px;
    font-size: 26px;
    padding: 0;
}
.nav__wrapper .m-auto {
    margin: auto;
}
.nav__wrapper .text--green {
    color: var(--primary-green) !important;
}
.nav__wrapper .cta:hover {
    background-color: var(--primary-red--dark-1);
}
.nav__wrapper .d-flex {
    display: flex;
}
.nav__wrapper hr.wavy {
    border: none;
}
.nav__wrapper hr.wavy:after {
    content: "";
    margin-block: 0.25rem 0.5;
    min-width: 91px;
    height: 12px;
    width: 100%;
    aspect-ratio: 6/1;
    display: block;
    margin-bottom: 1rem;
    background-image: url("data:image/svg+xml,%3Csvg width='91' height='12' viewBox='0 0 91 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.03209 9.39708C8.24148 5.01288 24.7012 -1.63121 41.8135 4.23124C65.4642 12.3336 80.0411 11.0063 89.2627 6.42786' stroke='%23ED7208' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
}

.nav__wrapper img {
    max-width: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.nav__wrapper .by-subocea img {
    width: 45px;
    height: 7px;
    color: #ED7208;
}
.nav__wrapper .container--img {
    max-width: 1400px;
    margin: auto;
}
@media (min-width: 1250px) {
    .nav__wrapper .container {
        max-width: 1000px;
        margin: auto;
    }
}

/***********END STYLE***************/
.destination-nav-menu {
    scroll-behavior: smooth;
}
.destination-nav-menu {
    font-family: Raleway;
}
.destination-nav-menu b {
    font-weight: bold;
}
.destination-nav-menu h3 {
    font-size: 2rem;
}
.destination-nav-menu h4 {
    font-size: 1.5rem;
}

.destination-nav-menu .cta {
    padding: 0.875rem 1.5rem;
    border-radius: 3rem;
    background: var(--primary-red);
    color: white !important;
    border: none;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 300ms;
    font-size: 0.75rem;
    display: block;
    width: fit-content;
}
body .destination-nav-menu button {
    border: none;
    background-color: transparent;
    font-size:100%;
    font-family: 'Arial';
    line-height: unset;
}
body .destination-nav-menu button:hover {
    color: #02061f;
}
.region-button.active {
    color: #02061f;
}
.whoare_button{
    font-size:100%;
}
.destination-nav-menu .m-auto {
    margin: auto;
}
.destination-nav-menu .text--green {
    color: var(--primary-green) !important;
}
.destination-nav-menu .cta:hover {
    background-color: var(--primary-red--dark-1);
}
.destination-nav-menu .d-flex {
    display: flex;
}
.destination-nav-menu hr.wavy {
    border: none;
}
.destination-nav-menu hr.wavy:after {
    content: "";
    margin-block: 0.25rem 0.5;
    min-width: 91px;
    height: 12px;
    width: 100%;
    aspect-ratio: 6/1;
    display: block;
    margin-bottom: 1rem;
    background-image: url("data:image/svg+xml,%3Csvg width='91' height='12' viewBox='0 0 91 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.03209 9.39708C8.24148 5.01288 24.7012 -1.63121 41.8135 4.23124C65.4642 12.3336 80.0411 11.0063 89.2627 6.42786' stroke='%23ED7208' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
}

.destination-nav-menu img {
    max-width: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.destination-nav-menu .by-subocea img {
    width: 45px;
    height: 7px;
    color: #ED7208;
}
.destination-nav-menu .container--img {
    max-width: 1400px;
    margin: auto;
}
@media (min-width: 1250px) {
    .destination-nav-menu .container {
        max-width: 1000px;
        margin: auto;
    }
}
/******END STYLE 2******/
.nav__wrapper {
    top: 0;
    position: sticky;
    z-index: 100;
}
body .nav__wrapper .navigation-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    padding: 10px 50px;
    column-gap: 100px;
}
body #subocea-navigation {
    transition: color 400ms, background-color 400ms;
}

body #subocea-navigation .logo{
    padding-block: 0.5rem;
    color: inherit;
}

body #subocea-navigation.nav-filled {
    background-color: #fff;
    color: #000b1d;
}
.navigation-menu > ul {
    display: flex;
    flex: 1 1;
   justify-content: flex-end;
    column-gap: 14px;
}

body .secondary-nav {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    background-color: var(--primary-green);
    color: white;
}
body .secondary-nav > div:not(:last-child){
    flex: 1;
}

.secondary-nav svg{
    margin-right: 0.5rem;
}

.secondary-nav .contribution  {
    display: flex;
    align-items: center;
}

.secondary-nav .contact  {
    display: flex;

}
.secondary-nav a{
    display: flex;
    align-items: center;
    margin-right: 1rem;
    color:inherit;
}

.navigation-menu > ul li,
.navigation-menu > ul a {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    text-decoration: none;
    color: inherit;
}

.expedition-nav {
    flex-direction: column;
    position: relative;
}

body #subocea-navigation.nav-filled .expedition-nav svg {
    color: var(--primary-red);
}

#destination-nav-item {
    cursor: pointer;
}
#who-nav-item {
     cursor: pointer;
 }

/* Mobile */

.burger-icon {
    color: inherit;
    width: 1.125rem;
    height: 1rem;
    border-top: 2px solid currentColor;
    position: relative;
    display: block;
    margin-right: 1rem;
    cursor: pointer;
}
.burger-icon:before,
.burger-icon:after {
    content: "";
    transition: width 300ms;
    position: absolute;
    height: 2px;
    width: 80%;
    top: 100%;
    left: 0;
    background-color: currentColor;
}

.burger-icon:before {
    width: 30%;
    top: calc(50% - 1px);
}
.burger-icon:hover:before,
.burger-icon:hover:after {
    width: 100%;
}

/* #destinations-mobile {
    position: fixed;
    padding: 1rem;
    left: 0;
    top: 1rem;
    background-color: #fff;
    color: #333333;
    height: calc(100% - 1rem);
    width: calc(100% - 1rem);
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    box-sizing: border-box;
    overflow: scroll;
    width: 100%;
} */
#destinations-mobile.hidden {
    display: none;
}
details {
    display: block;
}

details > *:not(summary) {
    margin-left: 1.5rem;
    margin-block: 0.5rem;
}
details[open] > summary {
    background-color: #e5f0ee;
    color: var(--primary-green);
}
details[open] > summary:before {
    background-color: #e5f0ee;
}
details[open] > summary:after {
    transform: rotate(0deg);
}
li {
    list-style: none;
}
#destinations-mobile a {
    color: #333333;
    display: block;
    font-weight: bold;
}

#destinations-mobile > a {
    margin-left: 0;
}
#destinations-mobile summary::-webkit-details-marker,
#destinations-mobile summary::marker {
    display: none;
}
#destinations-mobile > li,
#destinations-mobile > a {
    padding: 1rem;
    position: relative;
    padding-left: 1.4rem;
}

summary {
    position: relative;
    padding: 0.5rem;
    font-weight: bold;
}

summary:before {
    content: "";
    background-color: white;
    height: 1.5rem;
    width: 1.25rem;
    position: absolute;
    left: 0;
}
/* summary:after {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='9' viewBox='0 0 15 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 8.25L7.5 1.75L1 8.25' stroke='currentColor' stroke-width='1.5'/%3E%3C/svg%3E%0A");
    height: 9px;
    width: 15px;
    display: inline-block;
    margin-left: 1rem;
    transform: rotate(180deg);
} */

#destinations-mobile .mobile-menu-destination__item:hover {
    color: var(--primary-green);
}

/* Destination Navigation menu */
.region-nav-selector {
    padding-block: 1rem;
    background-color: var(--primary-green);
    box-shadow: -2rem 0 0 var(--primary-green), 2rem 0 0 var(--primary-green);
    color: white;
    margin-bottom: 1rem;
}

.region-nav-selector button {
    color: white;
}



.destination-nav-menu {
    position: fixed;
    height: fit-content;
    width: 100%;
    top: 112px;
    background-color: #fff;
    z-index: 100;
    padding: 2rem;
    padding-top: 0;

    box-sizing: border-box;
}
.destination-nav-menu:before {
    content: "";
    position: absolute;
    height: calc(100vh - 64px);
    width: 100%;
    top: 100%;
    left: 0;
    background-color: #8080808f;
    z-index: 100;

    box-sizing: border-box;
}
.destination-nav-menu.hidden {
    display: none;
}

.who-nav-menu {
    position: fixed;
    height: fit-content;
    width: 100%;
    top: 112px;
    background-color: #fff;
    z-index: 100;
    padding: 2rem;
    padding-top: 0;

    box-sizing: border-box;
}
.who-nav-menu:before {
    content: "";
    position: absolute;
    height: calc(100vh - 64px);
    width: 100%;
    top: 100%;
    left: 0;
    background-color: #8080808f;
    z-index: 100;

    box-sizing: border-box;
}
.who-nav-menu.hidden {
    display: none;
}
.region {
    display: flex;
    gap: 1rem;
}
.region h6 {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;

    margin-bottom: 0.75rem;
    gap: 0.5rem;
}
.region > div {
    flex: 1 1;
}
.region .d-flex {
    gap: 1rem;
}
.region-image__container {
    max-width: 23vw;
    max-height: 250px;
    aspect-ratio: 1/1;
    border-radius: 1rem;
    overflow: hidden;
    position: relative;
}
.region-image__container:after {
    content: "";
    background: linear-gradient(transparent, #0000008a);
    position: absolute;
    inset: 0;
}

.region-image__container .text {
    position: absolute;
    z-index: 1;
    width: 100%;
    box-sizing: border-box;
    font-size: 1.25rem;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
    align-items: center;
    color: white;
    bottom: 0;
    padding: 1rem;
    text-align: center;
}
#header{
    margin-top: -5rem;
}

@media (max-width: 1399px) {
    body .nav__wrapper .navigation-menu {
        column-gap: 40px;
    }
    .nav__wrapper .cta {
        margin-left: 0;
        padding: 10px 15px;
    }
    .navigation-menu > ul {
        column-gap: 30px;
    }
    .navigation-menu > ul li, .navigation-menu > ul a {
        text-align: center;
    }
}
@media (max-width: 1199px) {
    body .nav__wrapper .navigation-menu {
        column-gap: 40px;
        padding: 10px 20px;
    }
    .navigation-menu > ul {
        column-gap: 22px;
    }
    .navigation-menu > ul li, .navigation-menu > ul a {
        font-size: 14px;
    }
}
@media (max-width: 991px) {
    .nav__wrapper {
        position: fixed;
        width: 100%;
        z-index: 100;
    }
    .nav__wrapper .desktop-only {
        display: none !important;
    }
    .destination-nav-menu .desktop-only {
        display: none !important;
    }
    .burger-menu {
        summary {
            font-weight: 700;
            padding: 10px 0;
            display: flex;
            align-items: center;
            &:before {
                display: none;
            }
            &+ul li {
                padding: 10px 0;
                svg {
                    min-width: 25px;
                    height: 16px;
                }
            }
        }
        .cta {
            margin-top: 10px;
        }
    }
    #destinations-mobile > li, #destinations-mobile > a:not(.cta) {
        padding: 10px 0;
    }
    details[open] > summary {
        background-color: transparent;
    }
    details[open] > summary {
        background-color: transparent;
    }
    details[open] > summary + ul,
    details[open] > summary ~ details {
        margin-left: 20px;
    }
}

@media (min-width: 992px) {
    .nav__wrapper .mobile-only {
        display: none;
    }
    .destination-nav-menu .mobile-only {
        display: none;
    }
    .burger-menu {
        display: none;
    }
}