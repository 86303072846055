footer * {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    font-weight: normal;
    vertical-align: baseline;
    text-decoration: none;
}
/* HTML5 display-role reset for older browsers */
footer article,footer aside,footer details,footer figcaption,footer figure,
footer,footer header,footer hgroup,footer menu,footer nav,footer section {
    display: block;
}
footer {
    line-height: 1;
}
footer ol,footer ul {
    list-style: none;
}
footer blockquote,footer q {
    quotes: none;
}
footer blockquote:before,footer blockquote:after,
footer q:before,footer q:after {
    content: '';
    content: none;
}
footer table {
    border-collapse: collapse;
    border-spacing: 0;
}

/******END RESET********/
footer {
    scroll-behavior: smooth;
}
footer {
    font-family: Raleway;
}
footer b {
    font-weight: bold;
}
footer h3 {
    font-size: 2rem;
}
footer h4 {
    font-size: 1.5rem;
}

footer .cta {
    padding: 0.875rem 1.5rem;
    border-radius: 3rem;
    background: var(--primary-red);
    color: white !important;
    border: none;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 300ms;
    font-size: 0.75rem;
    display: block;
    width: fit-content;
}
footer button {
    border: none;
    background-color: transparent;
}
footer .m-auto {
    margin: auto;
}
footer .text--green {
    color: var(--primary-green) !important;
}
footer .cta:hover {
    background-color: var(--primary-red--dark-1);
}
footer .d-flex {
    display: flex;
}
footer hr.wavy {
    border: none;
}
footer hr.wavy:after {
    content: "";
    margin-block: 0.25rem 0.5;
    min-width: 91px;
    height: 12px;
    width: 100%;
    aspect-ratio: 6/1;
    display: block;
    margin-bottom: 1rem;
    background-image: url("data:image/svg+xml,%3Csvg width='91' height='12' viewBox='0 0 91 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.03209 9.39708C8.24148 5.01288 24.7012 -1.63121 41.8135 4.23124C65.4642 12.3336 80.0411 11.0063 89.2627 6.42786' stroke='%23ED7208' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
}

footer img {
    max-width: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
}
footer .by-subocea img {
    width: 45px;
    height: 7px;
    color: #ED7208;
}
footer .container--img {
    max-width: 1400px;
    margin: auto;
}
@media (min-width: 1250px) {
    footer .container {
        max-width: 1000px;
        margin: auto;
    }
}

@media (min-width: 750px) {
    footer .mobile-only {
        display: none;
    }
}
@media (max-width: 750px) {
    footer .desktop-only {
        display: none !important;
    }
}
/*******************END STYLE*********/

footer {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: center;
    background-image: url("data:image/svg+xml,%3Csvg width='300' height='206' viewBox='0 0 300 206' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-140 125.145C-120.197 109.439 -62.1553 81.0013 -17.0874 88.2705C46.4175 98.5134 103.777 133.339 171.379 121.048C225.46 111.215 266.294 81.4421 282 65.7362M-92.8835 32.9587C-72.3981 24.0815 -21.1836 4.27539 48.466 32.9587C138.602 70.0787 188.45 37.7385 206.204 26.8125M-84.6893 174.31C-64.2039 164.07 -13.9252 150.394 34.1262 174.31C67.06 190.702 105.825 192.751 142.699 178.411' stroke='%2358B99D' stroke-opacity='0.1' stroke-width='35' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    padding: 1rem 3rem;
}
footer > * {
    flex: 2 2;
}
footer a {
    color: black;
}
footer a:hover {
    color: black;
    text-decoration: none;
}
footer a:not(.contact-phone):hover {
    color: #58b99d;
    text-decoration: none;
}
footer .logo {
    height: auto;
    width: 200px;
}
footer .contacts {
    flex: 3 3;
}
footer .contacts .contact-phone {
    font-weight: bold;
    line-height: 1.5;
}
footer .contacts .contact-phone .small {
    margin-bottom: 1rem;
    font-size: 0.675rem;
}

footer .list h6 {
    font-weight: bold;
    margin-bottom: 1.25rem;
}
footer .list {
    min-width: 150px;
}
footer .list ul li {
    margin-bottom: 0.875rem;
}
footer .footer__social {
    display: flex;
    gap:0.5rem;
    margin-top: 10px;
}
footer .footer__social svg {
    color: var(--primary-red);
    width: 26px;
    height: 26px;

}

.footer-copyright {
    padding: 1rem;
    display: flex;
    justify-content: space-around;
    gap: 1rem;
    flex-wrap: wrap;
}
.footer-copyright a  {
    color: var(--primary-green);
}

@media screen and (max-width: 575px) { 
    footer {
        padding: 20px;
    }
}
